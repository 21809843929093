<template>
  <div>
    <apexchart :width="chartWidth" :height="chartHeight" :options="chartOptions" :series="fromSeries"></apexchart>
  </div>
</template>
<script>

import moment from "moment";
import {COMMONS} from "@/views/aprod/js/common";
//呼吸数30バージョン
function getChartOptions(chartTitle,dayCount,dayFromTo,ymin,ymax,colors,showXasis,xTitle,yTitle){
  // let xaxisAmount = 0;
  let yaxisTickAmount = 5;
  ymin = COMMONS.getYmin3(ymax,ymin,yaxisTickAmount);
  return {
    chart: {
      type: 'rangeBar',
      animations:{
        enabled:false
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,// pan: true,// reset: true,// customIcons: []
        }
      },
    },
    title: {text: chartTitle,align: 'center',offsetX: 0,offsetY: 5,floating: true,style: {  fontSize:  '17px',  fontWeight:  'bold',  /* fontFamily:  undefined,*/  color:  '#263238'}},
    fill:  {colors: colors},
    animations:{
      enabled:false
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: false,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        // console.log(data);
        let max = data.y[1];
        if(data.z == 1){
          max = max -1;
        }
        return (
            `<div class="">${data.x} ${data.y[0]}-${max} `+

            '</div>'
        );
      }
    },
    xaxis: {
      title:{text:xTitle},
      type: 'date',
      tickAmount:15,
      min: new Date(dayFromTo.from).getTime(),
      max: new Date(dayFromTo.to).getTime(),
      labels:{
        show:showXasis,
        rotate: 0,
        formatter: function (val,a,b) {
          // console.log("----",val,a,b);
          return moment(val).format("MM/DD")
        }
      },
    },
    yaxis:{
      title:{text:yTitle,offsetX: 8},
      min:ymin,
      max:ymax,
      tickAmount: yaxisTickAmount
    },
    legend: {
      show: false,
    }, plotOptions: {
      bar: {
        horizontal: false,
        // borderRadius: 10,
        columnWidth: '30%',
      }
    }
  }
}

export default {
  name: "OxBreathCharts30Print",
  props: {
    dayCount: {
      required: true
    },dayFromTo:{
      type:Object,
      "default":() => ({from:"2021-04-27 00:00:00",to:"2021-04-28 00:00:00"})
    },
    showXasis:{type:Boolean,default:true},
    ymin:{type:Number,default:0},
    ymax:{type:Number,default:300},
    // series: {type: Array, required: false},
    fromDate:{},
    chartType:{type:String},
    chartTitle:{type:String,default:"呼吸数"},
    chartHeight:{default:"200"},
    chartWidth:{default:"100%"},
    fromSeries:{type:Array},
    xTitle:{type:String},
    yTitle:{type:String},
    colors:{type:Array}
  },
  watch:{
    fromSeries(){
      this.chartOptions = getChartOptions(this.chartTitle,this.dayCount,this.dayFromTo,this.ymin,this.ymax,this.colors,this.showXasis,this.xTitle,this.yTitle);
    }
  },
  data() {
    return {
      series: [{
        name: '呼吸数',
        data: []
      }],
      chartOptions: getChartOptions(this.chartTitle,this.dayCount,this.dayFromTo,this.ymin,this.ymax,this.colors,this.showXasis,this.xTitle,this.yTitle)
    }
  },
};
</script>
<style>
/*.chart-regend{*/
/*  position: relative;*/
/*  display: inline-block;*/
/*  margin-right: 0.5em;*/
/*  white-space: nowrap;*/
/*  line-height: 0.7;*/
/*  margin-top: -20px;*/
/*  margin: 0 auto;*/
/*}*/
/*.cart-regend-item{*/
/*  !*padding:5px 3px;*!*/
/*  !*border:solid 1px rgb(180, 179, 179);*!*/
/*  vertical-align: middle;*/
/*  text-align: center;*/
/*  margin:0;*/
/*  !*position: relative;*!*/
/*  !*line-height: 15px;*!*/
/*}*/
/*#chart-wrap {*/
/*  max-width: 800px;*/
/*  position: relative;*/
/*  margin: 35px auto;*/
/*}*/

/*#chart {*/
/*  padding-bottom: 30px;*/
/*  max-width: 800px;*/
/*}*/

/*.legend {*/
/*  max-width: 800px;*/
/*  !*left: 80px;*!*/
/*  bottom: 20px;*/
/*  position: relative;*/
/*  text-align: right;*/
/*}*/
/*.card-tooltip-container{*/
/*  border: solid 1px sandybrown;*/
/*  width:250px;*/
/*  text-align: center;*/
/*  border-radius: 5%;*/
/*}*/
/*.card-tooltip-header{*/
/*  background-color: sandybrown;*/
/*  text-align: center;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*}*/
/*.card-tooltip-body{*/
/*  text-align: left;*/
/*}*/

</style>
